import React from "react";
import { useTranslation } from "react-i18next";
import people from "../../assets/images/people.webp";
import { ApiHelper, Employee as IEmployee } from "../../helper";

export const Employee: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const [employees, setEmployees] = React.useState<IEmployee[]>([]);

  const getAllEmployees = async (): Promise<void> => {
    const response = await ApiHelper.getAllEmployees();
    setEmployees(response);
  };

  React.useEffect((): void => {
    getAllEmployees();
  }, []);

  return (
    <>
      <section className="projects-section bg-light" id="team">
        <div className="container px-4 px-lg-5 ">
          <h2
            className="text-black mb-4"
            style={{ textAlign: "center", paddingBottom: "50px" }}
          >
            {t("employee.title")}
          </h2>
          <div className="d-flex  flex-wrap justify-content-center gap-5">
            {employees.length > 0 &&
              employees.map((employee, index) => {
                return (
                  <div
                    className="d-flex flex-column align-items-center text-center gap-3"
                    key={`employee-${index}`}
                  >
                    <div>
                      <img
                        src={people}
                        alt={`Our employer ${employee.name}`}
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                    <h4 className="text-black">{employee.name}</h4>
                    <span className="text-black-50">
                      <p>{employee.responsibility}</p>
                      <a
                        href={`mailto:${employee.email}`}
                        style={{
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                      >
                        <p>{employee.email}</p>
                      </a>
                      <a
                        href={`tel:${employee.phone}`}
                        style={{
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                      >
                        <p>{employee.phone}</p>
                      </a>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};
